.toastContainer {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 300px;  /* adjust width as per your needs */
    height: auto;
    z-index: 1000; /* to ensure it stays on top of other content */
}

.gbc-toast {
    position: absolute;
    border-radius: 4pt;
    bottom: -310px; /* slide them fully to the right, just a bit more than the container's width for safety */
    right: -310px;
    .toast-header{
        border-bottom: none;
    }
    .toast-body{
        background-color: #ffff;

    }
}