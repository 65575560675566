.footer {
    i {
        font-size: 1.5rem; // Adjust icon size
        cursor: pointer;

        &:hover {
            color: #ffd700; // Change hover color
        }
    }

    .rights-reserved {
        border-top: .5px solid var(--bs-body-bg);
    }
}

@media (max-width: 768px) {
    .footer i {
        display: block;
        margin: 10px auto;
    }
}