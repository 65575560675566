/** Buttons */
.gbc-btn{
    .bi{
        -webkit-text-stroke: 1px;
    }
    @extend .font-emphasized;
    font-size: unset;
    .bi-chevron-right {
        padding-left: 3px;
    }
    .bi-chevron-left{
        padding-right: 3px;
    }
}