
@include color-mode(light) {
    .navbar{
        --bs-emphasis-color-rgb: var(--bs-light-rgb);
    }
}

.navbar{
    .nav-link{
        border-radius: 20pt;
        
    }
    .nav-btns{
        position: absolute;
        @include for_breakpoint(mobileLarge){
           position: relative;
        }
    }
    .nav-btns-end, .navbar-brand{
        z-index: 1;
    }
}

.navbar-brand{
    display: flex;
    gap: 5px;
}

.navbar-brand-text{
    font-family: 'Poppins';
}

.nav-item.dropdown.show.drop-right .dropdown-menu[data-bs-popper]{
    right: 0;
    left: unset;
}
.nav-theme-container-offcanvas{
    display: flex;
    position: absolute;
    bottom: 10px;
    right: 0;
}

