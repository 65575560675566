
.stepped-cards-container {
    position: relative;
    width: 100%;
    display: flex; // Use flex to manage children
    flex-direction: column; // Stack children vertically
    align-items: center; // Center children horizontally
    transition: height 0.5s ease-in-out; // If you want to animate the container height

    // Pseudo-element to account for the height of transformed children
    &::after {
        content: '';
        display: block;
        height: calc((3 * var(--step-amount)) + var(--step-amount));
    }
    

    .cards-row {
        position: relative;
        top: var(--step-amount);
        z-index: 2; // Cards appear above the arcs
        display: flex; // Align cards in a row
        justify-content: space-around;
        width: 100%; // Make sure the row takes up the full width of the container
        flex-wrap: wrap;

        @include for_breakpoint(mobile) {
            flex-wrap: nowrap;
            flex-direction: row;
            gap: 30px;
            left: 100%;
        }
    }

    .step-card {
        flex: 0 0 auto; // Do not grow or shrink
        max-width: 15rem;
        cursor: pointer;
        transition: transform 0.5s ease-in-out, box-shadow 0.3s ease; // Add box-shadow transition for effect
        transform-origin: top center; // Set the pivot point at the top center
        transform: translateY(0); // Start position for the animation
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2); // Optional: add a shadow to lift the card
    }


    .connecting-lines {
        position: absolute;
        top: 0; // Adjust if necessary to fit the arcs
        left: 0;
        width: 100%;
        height: 200px; // Adjust if necessary to prevent clipping
        overflow: visible;
        z-index: 1; // Arcs appear behind the cards
        transition: opacity 0.2s ease-in-out; // Transition for the fade-in effect
    }

    .connecting-circles {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: visible;
        z-index: 3; // Above the cards
        transition: opacity 0.5s ease-in-out;
        pointer-events: none
    }
}