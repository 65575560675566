/**
This file controls the sizing of page elements. 
For small components put sizing in there component file (styles/components/componentName.scss)
*/

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
  7: $spacer * 12,
  8: $spacer * 24,
);

/* Media Queries */  
$media_queries : (
    'mobile'    : unquote("only screen and (max-width: 667px)"),
    'mobileLarge' : unquote("only screen and (max-width: 767px)"),
    'tablet'    : unquote("only screen and (min-width: 668px) and (max-width: $size__site_content_width)"),
    'desktop'   : unquote("only screen and (min-width: ($size__site_content_width + 1))"),
    'retina2'   : unquote("only screen and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi)"),
    'retina3'   : unquote("only screen and (-webkit-min-device-pixel-ratio: 3) and (min-resolution: 288dpi)"),
    'landscape' : unquote("screen and (orientation:landscape) "),    
    'portrait'  : unquote("screen and (orientation:portrait) ")
);
@mixin for_breakpoint($breakpoints) {
  $conditions : ();
  @each $breakpoint in $breakpoints {
      // If the key exists in the map
      $conditions: append(
          $conditions,
          #{inspect(map-get($media_queries, $breakpoint))},
          comma
      );
  }
  @media #{$conditions} {
      @content;
  }
}

$sizes : (
    "navbar": 50px,
    "navbar-width": 95%,
    "navbar-width-mobile": 100%,
    "navbar-padding-top": 15px,
    "footer-min-height": 220px
);

.nav-wrapper{
    padding-top: map-get($map: $sizes, $key: "navbar-padding-top");
    @include for_breakpoint(mobileLarge){
        padding-top: 0;
    }
}
#gbc-nav{
    height: map-get($map: $sizes, $key: "navbar");
    width: map-get($map: $sizes, $key: "navbar-width");
    margin: auto;
    max-width: 95vw;
    border-radius: 20pt;
    
    @include for_breakpoint(mobileLarge){
        width:  map-get($map: $sizes, $key: "navbar-width-mobile");
        border-radius: 0pt;
        max-width: unset;
    }
}

html{
    overflow-x: hidden;
}

#main{
    position: relative;
    width: 100%;
    min-height: calc(100vh - map-get($map: $sizes, $key: "footer-min-height"));
}

footer{
    min-height: map-get($map: $sizes, $key: "footer-min-height");
}

:export {
    @each $name, $size in $sizes {
        #{$name}: $size
      }
}
