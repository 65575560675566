.emoji-image-container {
    position: relative;
    display: inline-block; /* Makes the container fit the size of the image */
    cursor: pointer;
    user-select: none;


  .emoji {
    position: absolute;
    animation: fadeOut 3s forwards;
    user-select: none;
    transform: translate(-50%, -50%); /* Center the heart on the cursor */
  }
  
  @keyframes fadeOut {
    from {
        opacity: 1;
      }
    to {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1.2) rotate(var(--rotate)); /* Apply the rotation */
    }
  }
}