/** Colors */
// $blue: #005aa5;
$gray-900: #2e2e2e;
// $green: #24635e;
// $pink: #CC00B2;
// $red: #8f0444;
//$yellow:#E4FF00;
/* SCSS RGB */


//$paynes-gray: rgb(63, 91, 95);
$isabelline: rgb(255, 255, 255);

$paynes-gray: rgba(71, 106, 111, 1);
//$isabelline: rgba(237, 231, 226, 1);
$indian-red: rgba(208, 83, 83, 1);
$chocolate-cosmos: rgba(81, 28, 41, 1);
$black: rgba(4, 4, 4, 1);




/** Theme */
$primary: $paynes-gray;
$secondary: $indian-red;
$body-bg: $isabelline;


/** Typography */
//$enable-rounded: false;
$font-family-sans-serif: 'Nunito', sans-serif;
$font-family-serif: serif;
$headings-font-family: 'Poppins', sans-serif;
$font-weight-normal: 500;

/* Navbar Component*/
$navbar-padding-y: 0.1rem;


/** Form Inputs */
$input-btn-padding-y: 0.1rem;
$input-btn-padding-x: 0.3rem;

@mixin engraved($color, $hover: $white) {
    color: rgba($color, 0.3);
    text-shadow: 0px 1px 0px rgba($color, 0.3), 0px -1px 0px rgba(0, 0, 0, .7);
    font-weight: bolder;
    text-decoration: none;
    transition: color .5s ease;

    &:hover,
    &.active {
        color: rgba($hover, 0.5);
    }
}

@import '~bootstrap/scss/bootstrap';

@include color-mode(light) {
    --bs-body-color: $gray-900;
    --bs-card-color: $gray-900;
    --bs-light-rgb: 255, 255, 255;
    --bs-border-color: #{rgba($primary, 30%)};
   
}

@include color-mode(dark) {
    --bs-body-color: #{$isabelline};

    #main,
    main,
    .nav-wrapper {
        background-color: var(--bs-body-bg);
        --bs-body-color: #{$isabelline};
        color: var(--bs-body-color);
    }

    .badge {
        color: var(--bs-body-bg);
        font-weight: $font-weight-bolder;
    }
}


/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* This styles the scrollbar track (the part the thumb slides along) */
::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Light grey track */
}

/* This styles the scrollbar thumb (the draggable element) */
::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Dark grey thumb */
    border-radius: 10px;
    /* Rounded corners on the thumb */
}

/* Changes the thumb color on hover or when being clicked */
::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Darker grey on hover */
}

/* This styles the main scrollbar container */
::-webkit-scrollbar {
    width: 10px;
    /* Width of the scrollbar */
    height: 10px;
    /* Also applicable for horizontal scrollbars */
}



@import "bootstrap-icons/font/bootstrap-icons.css";