.page-jobs, .modal-jobs{

    .status-box{
        width: 100px;
    }

    .history-box{
        width: 50px;
    }

    .create-grid {  
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 5px 5px;
        grid-auto-flow: row;
        grid-template-areas:
          ". ."
          ". .";
          
          .grid-btn{
            border-radius: 4pt;
           
          }
      }
      

}