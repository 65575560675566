$timing : ("jumbleRocket": 1,
    "shootOffRocket": 0.5,
    "returnDelayRocket": 4.5,
    "returnRocket":0.5);

$sizing : ("stepAmount": 40,
);

.page-home {




    /* CSS for the rocket animation */
    .rocket {
        display: inline-block;
        position: relative;
        z-index: 100;

    }

    @keyframes jumbleRocket {

        0%,
        100% {
            transform: translate(0, 0)
        }

        10% {
            transform: translate(-5px, -2px)
        }

        20% {
            transform: translate(5px, -1px)
        }

        30% {
            transform: translate(-5px, 0)
        }

        40% {
            transform: translate(5px, -1px)
        }

        50% {
            transform: translate(-5px, -1px)
        }

        60% {
            transform: translate(5px, 0)
        }

        70% {
            transform: translate(-5px, -1px)
        }

        80% {
            transform: translate(5px, 0)
        }

        90% {
            transform: translate(-5px, -1px)
        }
    }

    @keyframes shootOff {
        0% {
            transform: translate(0, 0) rotate(0deg);
        }

        100% {
            transform: translate(1000px, -1000px) rotate(50deg);
        }
    }

    @keyframes returnRocket {
        0% {
            transform: translate(-1000px, 1000px);
        }

        100% {
            transform: translate(0, 0);
        }
    }

    .rocket-animate {
        animation: jumbleRocket #{map-get($map: $timing, $key: 'jumbleRocket')}s ease,
        shootOff #{map-get($map: $timing, $key: 'shootOffRocket')}s ease forwards #{map-get($map: $timing, $key: 'jumbleRocket')}s,
        returnRocket #{map-get($map: $timing, $key: 'returnRocket')}s ease forwards #{map-get($map: $timing, $key: 'returnDelayRocket')}s;
        /* 1s delay for pause */
    }

    .hero-text {
        font-size: 55pt;
        cursor: default;

        @include for_breakpoint(mobileLarge) {
            font-size: 35pt;
        }
    }

    .get-in-touch {
        cursor: pointer;
        text-align: right;
    }

    .service-text {
        font-size: 32pt;
    }

    .service-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 63px;
        row-gap: 38px;

        @include for_breakpoint(mobile) {
            overflow-x: scroll;
        }
    }

    .service-scroll {
        display: none;

        &::-webkit-slider-thumb {
            background: $secondary;
        }

        &::-moz-range-thumb {
            background: $secondary;
        }

        &::-ms-thumb {
            background: $secondary;
        }

        &::-webkit-slider-runnable-track {
            background: linear-gradient(to right, lighten($primary, 20%) var(--thumb-percentage), $isabelline var(--thumb-percentage));
            // background: linear-gradient(to right, red 50%, white 50%); /* Half red, half white */
        }

        @include for_breakpoint(mobile) {
            display: inline-block;
        }
    }


    .choose-grid {
        @include for_breakpoint(mobile) {
            .choose-grid-container {
                padding: 0;
            }
        }
    }

    .experience-strip {
       

        .threed-badge {
            /* 3D effect using box shadow and text shadow */
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08), 0 10px 20px rgba(0, 0, 0, 0.06);
            text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.25);
        }

        .marquee-position {
            width: 100vw;
            position: absolute;
            right: 50%;
            transform: translate(50%, -50%);
            max-width: 5120px;
        }

        .marquee-fade {
            position: absolute;
            top: 0;
            width: 100%;
            height: calc(100% + 5px);
            z-index: 1;
            /* Transparent background */
            background: transparent;

            /* Gradient on the left and right */
            background-image: linear-gradient(to right, var(--bs-body-bg), transparent), linear-gradient(to left, var(--bs-body-bg), transparent);

            /* Positioning gradients */
            background-position: left, right;
            background-size: 20% 100%, 20% 100%;
            /* adjust the width of the fading effect */
            background-repeat: no-repeat;
        }

    }


    .proudly-canadian {
        * {
            opacity: 0;
            transition: opacity ease-in 0.5s;
        }

        &.intersecting * {
            opacity: 100;
        }

    }





}

:export {
    @each $name, $time in $timing {
        home-timing-#{$name}: $time
    }
}