
//Page wrapper class from main layout .page-{which}
.page-login{

    .login-brand-container{
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .login-card {
        margin: auto;
        margin-top: 30px;
        max-width: 600px;
        
    }

}



