
.tiled-card-container {
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Autofit */
    grid-template-columns: repeat(3, 1fr);
    /* 3 items per row */
    gap: 1rem;
    padding: 1rem;

    /* Responsive design for smaller screens */
    @include for_breakpoint(mobileLarge) {
        grid-template-columns: repeat(2, 1fr);
    }

    

    .grid-item {
        //background: #f8f9fa;
        border: 1px solid rgba($primary, 0.3);
        border-radius: 0.25rem;
        padding: 1rem;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        cursor: pointer;
        display: flex;
        flex-direction: column;

        * {
            opacity: 0;
            transition: opacity ease-in 0.5s;
        }

        &.intersecting * {
            opacity: 100;
        }

    }

    .grid-item:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .tile-title {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;

        @include for_breakpoint(mobile) {
            font-size: 15pt;
        }
    }

    .tile-description {
        font-size: 1rem;

        @include for_breakpoint(mobile) {
            font-size: 8pt;
        }
    }

}