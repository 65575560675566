//Page wrapper class from main layout .page-{which}
.page-users{
    .badge {
        font-family: $font-family-serif;
        border: 3px solid transparent;
    }
    .badge.role-search{
        cursor: pointer;
    }

    .badge.muted{
        --bs-bg-opacity: 0.5;
    }

    .badge:not(.muted){
        border-color: $black;
    }




}