.gbc-pagination .active{
    transform: translate(0, -2px);
}
@include color-mode(light) {
    .gbc-pagination .active{
        box-shadow: 5px 7px 6px $gray-400;
    }
}

@include color-mode(dark) {
    .gbc-pagination .active span{
       color: var(--bs-body-bg);
       font-weight: $font-weight-bolder;
    }
}

.gbc-pagination .end {
    a, span{
        border: none;
        background-color: unset;
        outline: none;
    }
}

.gbc-pagination a:focus, span:focus {
    box-shadow: unset;
}